<!-- 平滑滚动在某浏览器 需要手动设置：
  chrome://flags 搜索 smooth 将 Smooth Scrolling 设置为 enabled 
  Edge://flags 搜索 smooth 将 Smooth Scrolling 设置为 enabled 
-->
<template>
  <div class="container">
    <div class="bg">
      <img class="bghead" src="../../assets/images/product/bghead.jpg" alt="" />
    </div>
    <div class="header">
      <div class="header-content">
        <img class="caption" src="../../assets/images/product/head_title.png" alt="" />
        <a class="instructions" href="/help">
          <span>操作指南</span>
          <!-- <img :src="ellipsePath" alt=""> -->
          <i></i>
        </a>
      </div>
      <div class="btn-content">
        <div class="four-btn">
          <div :class="item.show ? 'btn-show' : 'btn'" v-for="(item, index) in moduleList" :key="index"
            @click="checked(index, item.id)">
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <!-- -------壁纸-------看晕了，分个栏------- -->
      <div class="page">
        <div class="headline">
          <div class="symbol"></div>
          <h2>壁纸</h2>
        </div>
        <div class="card-group" id="bz">
          <div class="card">
            <div class="subtitle">
              <span>动态壁纸</span>
              <!-- <img :src="playPath" @mouseenter="playOn()" @mouseleave="playOut()" alt=""> -->
              <i @click="playVideo('04')"></i>
            </div>
            <div class="introduce">
              <span>超强播放引擎，</span>
              <span>支持4/8K视频完美显示在桌面。</span>
            </div>
            <div class="image">
              <img src="../../assets/images/product/dtbz.png" alt="" />
            </div>
          </div>
          <div class="card">
            <div class="subtitle">
              <span>互动壁纸</span>
            </div>
            <div class="introduce">
              <span>支持高阶壁纸玩法，</span>
              <span>多种游戏壁纸互动感、可玩性极强。</span>
            </div>
            <div class="image">
              <img src="../../assets/images/product/hdbz.png" alt="" />
            </div>
          </div>
          <div class="card">
            <div class="subtitle">
              <span>静态壁纸</span>
            </div>
            <div class="introduce">
              <span>海量无损原画壁纸，</span>
              <span>轻松拥有优质在线图库。</span>
            </div>
            <div class="image">
              <img src="../../assets/images/product/jtbz.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <!-- -------电脑美化-------看晕了，分个栏------- -->
      <div class="page">
        <div class="headline">
          <div class="symbol"></div>
          <h2>电脑美化</h2>
        </div>
        <div class="card-group" id="dnmh">
          <div class="card">
            <div class="subtitle">
              <span>鼠标</span>
              <i @click="playVideo('12')"></i>
            </div>
            <div class="introduce">
              <span>一键更换鼠标，</span>
              <span>让喜爱的角色在指间跃动。</span>
            </div>
            <a class="instruction" href="/help?id=1029">
              <span>查看操作指南</span>
              <!-- <img :src="arrowPath" alt=""> -->
              <i></i>
            </a>
            <div class="image">
              <img src="../../assets/images/product/mouse.png" alt="" />
            </div>
          </div>
          <div class="card">
            <div class="subtitle">
              <span>图标</span>
              <i @click="playVideo('14')"></i>
            </div>
            <div class="introduce">
              <span>更改电脑系统图标和磁盘图标，</span>
              <span>美化电脑系统内部样式。</span>
            </div>
            <a class="instruction" href="/help?id=1033">
              <span>查看操作指南</span>
              <!-- <img :src="arrowPath" alt=""> -->
              <i></i>
            </a>
            <div class="image">
              <img src="../../assets/images/product/tb.png" alt="" />
            </div>
          </div>
          <div class="card">
            <div class="subtitle">
              <span>任务栏</span>
              <i @click="playVideo('10')"></i>
            </div>
            <div class="introduce">
              <span>点击更换任务栏，自带任务栏编辑器，</span>
              <span>上传图片即可自定义任务栏样式。</span>
            </div>
            <a class="instruction" href="/help?id=1035">
              <span>查看操作指南</span>
              <!-- <img :src="arrowPath" alt=""> -->
              <i></i>
            </a>
            <div class="image">
              <img src="../../assets/images/product/rwl.png" alt="" />
            </div>
          </div>
          <div class="card">
            <div class="subtitle">
              <span>开始菜单</span>
              <i @click="playVideo('07')"></i>
            </div>
            <div class="introduce">
              <span>修改开始菜单的样式，支持将开始菜单</span>
              <span>修改为动漫、游戏、萌宠等样式。</span>
            </div>
            <a class="instruction" href="/help?id=1034">
              <span>查看操作指南</span>
              <!-- <img :src="arrowPath" alt=""> -->
              <i></i>
            </a>
            <div class="image">
              <img src="../../assets/images/product/kscd.png" alt="" />
            </div>
          </div>
          <div class="card">
            <div class="subtitle">
              <span>美化套餐</span>
              <i @click="playVideo('08')"></i>
            </div>
            <div class="introduce">
              <span>优质原创主题套餐，</span>
              <span>一键美化电脑系统样式</span>
            </div>
            <a class="instruction" href="/help?id=1036">
              <span>查看操作指南</span>
              <!-- <img :src="arrowPath" alt=""> -->
              <i></i>
            </a>
            <div class="image">
              <img src="../../assets/images/product/mhtc.png" alt="" />
            </div>
          </div>
          <div class="card _empty">
          </div>
        </div>
      </div>

      <!-- -------全能组件-------看晕了，分个栏------- -->
      <div class="page">
        <div class="headline">
          <div class="symbol"></div>
          <h2>全能组件</h2>
        </div>
        <div class="card-group" id="qnzj">
          <div class="card">
            <div class="subtitle">
              <span>蜂巢收纳</span>
              <i @click="playVideo('06')"></i>
            </div>
            <div class="introduce">
              <span>六边形的收纳格可容纳桌面图标</span>
              <span>及文件，轻松整理桌面文件。</span>
            </div>
            <a class="instruction" href="/help?id=1039">
              <span>查看操作指南</span>
              <!-- <img :src="arrowPath" alt=""> -->
              <i></i>
            </a>
            <div class="image">
              <img src="../../assets/images/product/fcsn.png" alt="" />
            </div>
          </div>
          <div class="card">
            <div class="subtitle">
              <span>任务栏优化</span>
              <i @click="playVideo('11')"></i>
            </div>
            <div class="introduce">
              <span>支持任务栏透明，自定义样式、</span>
              <span>颜色、位置，快速调整电脑音量。</span>
            </div>
            <a class="instruction" href="/help?id=1040">
              <span>查看操作指南</span>
              <!-- <img :src="arrowPath" alt=""> -->
              <i></i>
            </a>
            <div class="image">
              <img src="../../assets/images/product/rwlyh.png" alt="" />
            </div>
          </div>
          <div class="card">
            <div class="subtitle">
              <span>待办清单</span>
              <i @click="playVideo('03')"></i>
            </div>
            <div class="introduce">
              <span>指定工作学习计划，高效完成任务。</span>
              <span>可作为简易记事本使用，</span>
              <span>重要事项一刻不落。</span>
            </div>
            <a class="instruction" href="/help?id=1037">
              <span>查看操作指南</span>
              <!-- <img :src="arrowPath" alt=""> -->
              <i></i>
            </a>
            <div class="image">
              <img src="../../assets/images/product/todolist.png" alt="" />
            </div>
          </div>
          <div class="card">
            <div class="subtitle">
              <span>桌面宠物</span>
              <i @click="playVideo('17')"></i>
            </div>
            <div class="introduce">
              <span>二次元萌宠时刻陪伴互动，可以结合</span>
              <span>待办清单使用，轻松get一个电脑助手。</span>
            </div>
            <a class="instruction" href="/help?id=1038">
              <span>查看操作指南</span>
              <!-- <img :src="arrowPath" alt=""> -->
              <i></i>
            </a>
            <div class="image">
              <img src="../../assets/images/product/zmcw.png" alt="" />
            </div>
          </div>
          <div class="card">
            <div class="subtitle">
              <span>动态屏保</span>
              <i @click="playVideo('09')"></i>
            </div>
            <div class="introduce">
              <span>炫酷动态屏保保护电脑屏幕，</span>
              <span>离开电脑后遮挡桌面隐私。</span>
            </div>
            <a class="instruction" href="/help?id=1056">
              <span>查看操作指南</span>
              <!-- <img :src="arrowPath" alt=""> -->
              <i></i>
            </a>
            <div class="image">
              <img src="../../assets/images/product/dtpb.png" alt="" />
            </div>
          </div>
          <div class="card">
            <div class="subtitle">
              <span>音乐频谱</span>
              <i @click="playVideo('16')"></i>
            </div>
            <div class="introduce">
              <span>超5种音乐可视化频谱，</span>
              <span>调整频谱大小，自定义位置和颜色，</span>
              <span>直观展现音乐的美感</span>
            </div>
            <a class="instruction" href="/help?id=1041">
              <span>查看操作指南</span>
              <!-- <img :src="arrowPath" alt=""> -->
              <i></i>
            </a>
            <div class="image">
              <img src="../../assets/images/product/yypp.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <!-- -------隐藏小功能-------看晕了，分个栏------- -->
      <div class="page">
        <div class="headline">
          <div class="symbol"></div>
          <h2>隐藏小功能</h2>
        </div>
        <div class="card-group" id="ycxgn">
          <div class="card">
            <div class="subtitle">
              <span>双击隐藏桌面文件</span>
              <i @click="playVideo('13')"></i>
            </div>
            <div class="introduce">
              <span>双击桌面即可隐藏桌面上所有文件，</span>
              <span>杂乱从此看不见。</span>
            </div>
            <a class="instruction" href="/help"><!-- 没对应的操作指南啊 -->
              <span>查看操作指南</span>
              <!-- <img :src="arrowPath" alt=""> -->
              <i></i>
            </a>
            <div class="image">
              <img src="../../assets/images/product/ycwj.png" alt="" />
            </div>
          </div>
          <div class="card">
            <div class="subtitle">
              <span>多屏壁纸</span>
              <i @click="playVideo('05')"></i>
            </div>
            <div class="introduce">
              <span>多显示器桌面有三种模式可选：</span>
              <span>全部、单屏、多屏，支持不同显示器</span>
              <span>展示不同壁纸。</span>
            </div>
            <a class="instruction" href="/help?id=1047">
              <span>查看操作指南</span>
              <!-- <img :src="arrowPath" alt=""> -->
              <i></i>
            </a>
            <div class="image">
              <img src="../../assets/images/product/dpbz.png" alt="" />
            </div>
          </div>
          <div class="card">
            <div class="subtitle">
              <span>本地打开</span>
              <i @click="playVideo('01')"></i>
            </div>
            <div class="introduce">
              <span>直接导入本地文件作为电脑壁纸使用，</span>
              <span>支持视频、图片和网页zip格式。</span>
            </div>
            <a class="instruction" href="/help?id=1043">
              <span>查看操作指南</span>
              <!-- <img :src="arrowPath" alt=""> -->
              <i></i>
            </a>
            <div class="image">
              <img src="../../assets/images/product/bddk.png" alt="" />
            </div>
          </div>
          <div class="card">
            <div class="subtitle">
              <span>播放列表</span>
              <i @click="playVideo('02')"></i>
            </div>
            <div class="introduce">
              <span>自定义播放列表内容，专属你的壁纸播</span>
              <span>放清单。</span>
            </div>
            <a class="instruction" href="/help?id=1052">
              <span>查看操作指南</span>
              <!-- <img :src="arrowPath" alt=""> -->
              <i></i>
            </a>
            <div class="image">
              <img src="../../assets/images/product/bflb.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // ellipsePath: "",
      // arrowPath: "",
      // playPath: "",
      moduleList: [
        {
          name: "壁纸",
          show: true,
          id: "bz",
        },
        {
          name: "电脑美化",
          show: false,
          id: "dnmh",
        },
        {
          name: "全能组件",
          show: false,
          id: "qnzj",
        },
        {
          name: "隐藏小功能",
          show: false,
          id: "ycxgn",
        },
      ],
    };
  },
  methods: {
    playVideo(videoPath) {
      //console.log(`https://pcsource.upupoo.com/official_website/video/index_v_${videoPath}.mp4`)
      let data = {
        // url: require('../../assets/video/mobileWallpaper.mp4'),
        // url: "https://pcsource.upupoo.com/official_website/video/mobileWallpaper.mp4",
        url: `https://pcsource.upupoo.com/official_website/video/index_v_${videoPath}.mp4`,
        poster: "",
        typeVideo: 1,
      };
      this.$bus.$emit("showVideoModel", data);
    },
    // instructionIndex(pIdx) {
    //   console.log('有时候楼下直接去到了另一个组件的log，有时候不去');
    //   EventBus.$emit('instructionPIdx', pIdx)
    // },
    // 改样式，然后定位
    checked(index, id) {
      for (let i = 0; i < this.moduleList.length; i++) {
        this.moduleList[i].show = false;
      }
      this.moduleList[index].show = true;

      // let pageId = document.getElementById("id");
      // console.log(pageId.offsetTop)

      // pageId.scrollTop = pageId.offsetTop // 滚不动
      // pageId.scrollTo(0, pageId.offsetTop) // 滚不动
      document
        .getElementById(id)
        .scrollIntoView({ behavior: "smooth", block: "center" }); // 平滑滚动有问题
    },
    checkOutLink() {
      let id = this.$route.query.label,
        idx = -1;
      if (!id) return;
      this.moduleList.map((item, key) => {
        item.id == id && (idx = key);
      });
      this.checked(idx, id);
    },
  },
  // 外部link
  mounted() {
    this.checkOutLink();
  },

  // beforeDestroy() {
  //   EventBus.$off('instructionPIdx')
  // },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  min-width: 1300px;
  left: 50%;
  transform: translate(-50%, 0);
  // display: flex;
  // justify-content: center;
  // flex-direction: column;

  .bg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 374px;
    max-width: 1920px;
    transform: translate(-50%, 0);
    left: 50%;
    overflow: hidden;

    .bghead {
      position: absolute;
      transform: translate(-50%, 0);
      // top: -92px;
      left: 50%;
      // height: 374px;
      // left: -309px;
    }
  }

  .header {
    // margin-top: 92px;
    margin-bottom: 179px;
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;

    .header-content {
      position: relative;
      margin-top: 92px;
      width: 1300px;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;

      .caption {
        position: relative;
        width: 277px;
        height: 69px;
        // top: 92px;
        // left: 309px;
      }

      .instructions {
        position: relative;
        top: 53px;
        left: 15px;
        width: 89px;
        height: 16px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        span {
          width: 65px;
          height: 16px;
          line-height: 16px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #161616;
        }

        // img {
        //   width: 16px;
        //   height: 16px;
        // }

        i {
          width: 16px;
          height: 16px;
          background: url("../../assets/images/product/ellipse.png");
          background-size: 100% 100%;
        }

        &:hover span {
          color: rgba(61, 167, 254, 1);
        }

        &:hover i {
          background: url("../../assets/images/product/ellipse_on.png");
          background-size: 100% 100%;
        }
      }
    }

    .btn-content {
      margin-top: 51px;
      width: 1300px;
      display: flex;
      justify-content: flex-start;

      .four-btn {
        width: 606px;
        // width: 590px;
        display: flex;
        justify-content: space-between;

        .btn {
          width: 136px;
          height: 50px;
          // width: 140px;
          // height: 54px;
          background: rgba(22, 22, 22, 0.5);
          border: 2px solid transparent;
          border-radius: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: relative;
          transition: 0.2s;

          &:hover {
            background-color: rgba(61, 167, 254, 1);
            border: 2px solid #2197f9;
            box-shadow: 0px 0px 16px 0px rgba(22, 22, 22, 0.1);
            transition: 0.2s;

            // &::before {
            //   content: '';
            //   position: absolute;
            //   transform: translate(-50%, 0);
            //   left: 50%;
            //   bottom: -2px;
            //   width: 28px;
            //   height: 3px;
            //   background: #FFFFFF;
            //   border-radius: 2px;
            // }
          }

          span {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            line-height: 16px;
          }

          // a {
          //   display: flex;
          //   justify-content: center;
          //   align-items: center;
          //   height: 54px;
          //   width: 140px;
          //   font-size: 16px;
          //   font-family: Microsoft YaHei;
          //   font-weight: bold;
          //   color: #FFFFFF;
          //   line-height: 16px;
          // }
        }

        .btn-show {
          @extend .btn;
          background-color: rgba(61, 167, 254, 1);
          border: 2px solid #2197f9;
          box-shadow: 0px 0px 16px 0px rgba(22, 22, 22, 0.1);

          &::before {
            content: "";
            position: absolute;
            transform: translate(-50%, 0);
            left: 50%;
            bottom: -2px;
            width: 28px;
            height: 3px;
            background: #ffffff;
            border-radius: 2px;
          }
        }
      }
    }
  }

  .content {
    // width: 1300px;
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;

    .page {
      display: flex;
      flex-direction: column;
      width: 1300px;
      margin-bottom: 60px;

      .headline {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 40px;

        .symbol {
          width: 6px;
          height: 28px;
          background: #6f82fc;
        }

        h2 {
          padding-left: 12px;
          // width: 60px;
          height: 29px;
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 29px;
          color: #161616;
        }
      }

      .card-group {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;

        .card {
          // margin-right: 20px;
          margin-bottom: 20px;
          position: relative;
          width: 418px; // border占了左右各1像素
          height: 182px;
          background: #ffffff;
          border: 1px solid rgba(223, 223, 223, 0.94);
          border-radius: 16px;
          transition: 0.2s;

          &._empty {
            visibility: hidden;
          }

          &:hover {
            box-shadow: 0px 0px 16px 0px rgba(22, 22, 22, 0.1);
            transition: 0.2s;
          }

          .subtitle {
            margin-left: 18px;
            margin-top: 20px;
            // margin-top: 22px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            span {
              // width: 80px;
              // height: 20px;
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              line-height: 20px;
              color: #161616;
            }

            // img {
            //   margin-left: 12px;
            //   width: 26px;
            //   height: 26px;
            //   // background-color: rgb(199, 230, 255);
            //   cursor: pointer;
            // }

            i {
              margin-left: 10px;
              width: 26px;
              height: 26px;
              cursor: pointer;
              background: url("../../assets/images/product/play.png") no-repeat;
              background-size: 100% 100%;

              &:hover {
                background: url("../../assets/images/product/play_on.png") no-repeat;
                background-size: 100% 100%;
              }
            }
          }

          .introduce {
            // width: 190px;
            // height: 33px;
            margin-left: 18px;
            margin-top: 20px;

            span {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #161616;
              line-height: 20px;
              opacity: 0.8;
            }
          }

          .instruction {
            position: absolute;
            left: 18px;
            bottom: 23px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            span {
              width: 84px;
              height: 12px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 12px;
              color: #3da7fe;
            }

            // img {
            //   margin-left: 8px;
            //   width: 14px;
            //   height: 14px;
            // }

            i {
              margin-left: 8px;
              width: 14px;
              height: 14px;
              background: url("../../assets/images/product/arrow.png");
              background-size: 100% 100%;
            }

            &:hover span {
              color: #2197f9;
            }

            &:hover i {
              background: url("../../assets/images/product/arrow_on.png");
              background-size: 100% 100%;
            }
          }

          .image {
            position: absolute;
            top: 32px;
            right: 32px;
            width: 110px;
            height: 110px;
            // background: #9ECCFF;
            // opacity: 0.04;

            img {
              width: 110px;
              height: 110px;
            }
          }
        }
      }
    }
  }
}
</style>
